import * as React from "react"
import { graphql } from 'gatsby'
import Seo from "src/components/seo"
import AppLayout from "src/components/app/app-layout"

const title = "小学生クイズ王 個人情報保護方針";

const PrivacyContent = () => {
  return (
    <>
      <h1>{title}</h1>
      <section>
        <p>当社は、当社が提供するアプリケーションサービス「小学生クイズ王」（以下（「本アプリ」といいます。）におけるユーザー情報等の適切な取扱いの確保のため、以下のとおり、小学生クイズ王
          個人情報保護方針（以下「本保護方針」といいます。）を定めます。なお、本保護方針の用語の意義は別段の定めのない限り、本アプリの利用規約（以下「利用規約」といいます。）の定義に従います。</p>
      </section>

      <section>
        <h2>第1条（総則）</h2>
        <ol>
          <li>当社は、ユーザー情報等の保護実現のため、個人情報保護法その他関連する法令等を遵守いたします。本保護方針は、本アプリ（本アプリに付随して当社が提供するサービスを含みます。）の利用に関して適用されます。</li>
          <li>当社が当社の運営するウェブサイト上に掲載する個人情報保護方針又は利用規約においてユーザー情報等の取扱いについて規定する場合、当該規約等の規定（利用目的等）に加えて、本保護方針の規定（利用目的等）が適用されるものとします。規約等の規定が本保護方針の規定と抵触する場合には、本保護方針の規定が優先されるものとします。</li>
        </ol>
      </section>

      <section>
        <h2>第2条（取得する情報）</h2>
        <ol>
          <li>当社は、本アプリにおいて、以下に定める通り、個人情報（個人情報保護法第２条第１項により定義された「個人情報」をいい、以下同様とします。）を含むユーザー情報等を取得します。
            <ol>
              <li>ユーザーに提供いただく情報
                <ol>
                  <li>本アプリ内でユーザーが入力した情報</li>
                </ol>
              </li>
              <li>アクセス解析ツールを通じて収集する情報
                <ol>
                  <li>端末情報</li>
                  <li>
                    ユーザーID（本サービス利用時に自動的かつランダムに付与される文字列であり、個人を特定するものではありません）
                  </li>
                  <li>ログ情報及び行動履歴情報</li>
                  <li>Cookie及び匿名ID</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>当社は、ユーザー情報等の取得にあたっては、偽りその他不正の手段によらず、適正な方法により取得します。</li>
        </ol>
      </section>

      <section>
        <h2>第3条（利用目的）</h2>
        <ol>
          <li>当社は、本アプリの利用を通じて取得したユーザー情報等を、下記の目的の範囲内で適正に取り扱います。
            <ol>
              <li>本アプリにおける本人確認及び不正利用の防止のため</li>
              <li>本アプリの機能向上のため</li>
              <li>コミュニケーション機能、提携サービスへの連携を含む本アプリの円滑な提供、維持及び改善のため</li>
              <li>本アプリに関するユーザーへのアンケート等の実施のため</li>
              <li>本アプリに関する案内、問い合わせ等への対応のため</li>
              <li>本アプリに関連する新たなサービスの通知又は提供のため</li>
              <li>本アプリに関する利用規約又は本保護方針の変更、本アプリの停止・中止・契約解除その他本アプリの停止・中止・契約解除その他本アプリに関する重要なお知らせ等の通知のため</li>
              <li>関心、入力内容等の観点から分析することにより、個人を識別できない形式に加工した事例紹介用個別データ又は統計データ（以下「統計データ等」といいます。）を作成するため</li>
              <li>その他上記各号に付随する目的のため</li>
            </ol>
          </li>
          <li>当社及び統計データ等の提供を受けた第三者は、統計データ等を以下の目的のために利用します。
            <ol>
              <li>本アプリの紹介・宣伝のため</li>
              <li>広告効果分析のため</li>
              <li>市場分析、マーケティングのため</li>
              <li>その他上記各号に付随する目的のため</li>
            </ol>
          </li>
          <li>当社は、ユーザー情報等の利用目的を変更した場合には、当社が別途定める方法により、ユーザーに通知又は公表します。</li>
        </ol>
      </section>

      <section>
        <h2>第4条（第三者提供）</h2>
        <ol>
          <li>当社は、ユーザー情報等のうち、個人情報については、以下の場合を除き、第三者に提供することはありません。
            <ol>
              <li>ユーザーの同意を得た場合</li>
              <li>法令に基づく場合</li>
              <li>本アプリの利用上、ユーザーが他人の利益を害し若しくは公序良俗に反する行為その他本アプリの利用規約に違反する行為を行い又は行おうとするときに、当該行為に対して必要な措置を取る場合</li>
              <li>人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき</li>
              <li>公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき</li>
              <li>国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</li>
              <li>合併、会社分割、事業譲渡その他の理由によりユーザーの個人情報を含む事業の承継がなされる場合</li>
            </ol>
          </li>
          <li>本アプリはオンラインサービスの提供のために必要なクラウドサービスを利用しており、これに関連してユーザー情報等を当該サービス提供者に提供することがあります。ユーザーは本アプリを利用することで、当該提供について同意したものとみなします。</li>
        </ol>
      </section>

      <section>
        <h2>第5条（個人情報の取扱いの委託）</h2>
        <p>当社は、ユーザーから取得した個人情報の全部又は一部の取扱いを第三者に委託することがあります。この場合、当社は、当該委託先において情報の適切な安全管理が図られるよう、必要かつ適切な監督を行います。</p>
      </section>

      <section>
        <h2>第6条（Cookie等の利用）</h2>
        <ol>
          <li>本サービスは、クッキー（Cookie）、ウェブビーコン、UID等（以下「クッキー等」といいます。）ならびに、クッキー等を利用したGoogleアナリティクスなどのアクセス解析ツール（以下「アクセス解析ツール」といいます。）を使用しています。</li>
          <li>クッキー等またはアクセス解析ツール等を通じて、本サービスへの利用者のアクセス情報、閲覧情報、端末に関する情報（端末種別、OSバージョン等）を受け取っています。なお、これらの情報には、単独で利用者自身を識別し特定できる情報は含まれていません。</li>
          <li>
            当社は、クッキー等またはアクセス解析ツールを通じ、利用者による本サービス等の利用状況を把握し、サービスの改善、開発を行っています。
            また、多くのブラウザでは、Cookie を無効にしたり、Cookie がコンピュータに送信されたときに通知するように設定したりすることが可能です。
            また、Googleアナリティクスをオプトアウトすることも可能です。各アクセス分析ツールを利用した情報収集を停止したい場合及び各アクセス分析ツールのプライバシーポリシー等を確認する場合には、以下ご確認ください。
            <ol>
              <li>Google Analytics/Google Search Console/Google Tag Managerプライバシーポリシー<br/>
                提供者：<a href="https://policies.google.com/privacy?hl=ja\">Google LLC</a>
              </li>
              <li>Unity Analyticsプライバシーポリシー<br/>
                提供者：<a href="https://unity3d.com/jp/legal/privacy-policy\">Unity Technologies</a>
              </li>
            </ol>
          </li>
        </ol>
      </section>

      <section>
        <h2>第7条（個人情報の開示等）</h2>
        <p>
          当社は、ユーザー本人から保有個人データの開示の請求があったときは、法令に従って遅滞なく必要な調査を行い、開示に応じます。ただし、開示することにより次のいずれかに該当する場合は、個人データの全部または一部を開示しないこともあり、開示しない決定をした場合には、遅滞なくその旨をお客さまに通知します。なお、個人情報の開示にあたっては、手数料をいただく場合があります。
        </p>
        <ol>
          <li>お客様または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合</li>
          <li>当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合</li>
          <li>その他、法令に違反することとなる場合</li>
        </ol>
      </section>

      <section>
        <h2>第8条（個人情報の訂正等）</h2>
        <ol>
          <li>当社は、ユーザー情報等の取扱いに関する運用状況等を適宜見直し、継続的な改善に努めるものとし、必要に応じて、随時本保護方針を変更することがあります。</li>
          <li>変更後の本保護方針については、本サービス上又は当社の運営するウェブサイトでの掲示その他当社所定の方法により告知します。</li>
        </ol>
      </section>

      <section>
        <h2>第9条（規約等）</h2>
        <p>本保護方針に定めのない事項については、当社の規約等におけるユーザー情報等の取扱いの規定に従います。</p>
      </section>

      <section>
        <h2>第10条（お問い合わせ）</h2>
        <p>当社のユーザー情報等の取扱いに関するご意見、ご質問、苦情のお申出その他ユーザー情報等の取扱いに関するお問い合わせは、下記窓口までご連絡ください。</p>
      </section>

      <footer className="privacy-page__footer">
        <p>株式会社DanRan 問い合わせフォーム</p>
        <p><a target="_blank" href="https://dan-ran.co.jp/contact">https://dan-ran.co.jp/contact</a></p>
        <p className="privacy-page__revision">2024年12月1日 制定</p>
      </footer>

    </>
  )
}

const QuizKingPrivacy = () => {
  return (

    <AppLayout isShownHeader={false}>
      <Seo title={title} isIndex={false}/>

      <article className="container page-article-main privacy-page">
        <PrivacyContent/>
      </article>

    </AppLayout>
  )
    ;
}

export default QuizKingPrivacy

export const pageQuery = graphql`
query ($language: String!) {
  locales: allLocale(filter
:
  {
    language: {
      eq: $language
    }
  }
)
  {
    edges
    {
      node
      {
        ns
        data
        language
      }
    }
  }
}
`;
