import * as React from "react";
import Seo from "src/components/seo";
import Layout from "src/components/layout";
import {StaticImage} from "gatsby-plugin-image";
import GooglePlayButton, {quizKingGooglePlayUrl,} from "src/components/common/google_play_button";
import AppleStoreButton, {quizKingAppStoreUrl} from "src/components/common/apple_store_button";
import {BackHome} from "src/components/common/back_home";
import {graphql} from "gatsby";
import "src/styles/products/main.scss";

const title = "小学生クイズ王 - 1000問以上の豊富なクイズで楽しく学べる知育アプリ";
const description = "「小学生クイズ王」は、6歳から12歳の子供向けの無料知育アプリです。虫・乗り物・食べ物・植物など、1000問以上の豊富なクイズで楽しく知識が身につきます。全問ルビ付きで読みやすく、広告・課金なしで安心して遊べます。";

const QuizKing = () => {
  return (
    <Layout>
      <Seo title={title} description={description} pagePath={"/product/quiz_king"}/>
      <article className="container article-container">
        <header className="article-header"><h1>{title}</h1>
        </header>
        <section>
          <h2>小学生クイズ王について</h2>
          <p>「小学生クイズ王」は、お子様の知的好奇心を刺激する1000問以上の豊富なクイズを収録した知育アプリです。虫、乗り物、食べ物、花・植物など、小学生の興味を引くジャンルから厳選されたクイズで、楽しみながら知識を深めることができます。</p>
          <StaticImage
            src={"../../../images/quiz_king/quiz_king_feature_graphic.png"}
            alt="小学生クイズ王のご紹介"
          />

          <h2>「小学生クイズ王」の機能や特徴</h2>

          <h3>小学生が安心して使える</h3>
          <p>全問にルビが付いているので読みやすく、お子様ひとりでも安全に遊べる設計になっています。また、広告表示や課金要素が一切ないため、保護者の方も安心してお使いいただけます。</p>

          <h3>もっと学びたくなる工夫がいっぱい</h3>
          <p>ランキング機能を搭載しているため、友達と楽しく競い合うことができます。意外なクイズもたくさんあり、クイズの内容を学校や家庭で話、コミュニケーションのきっかけにも！正解時には楽し・」い演出があり、子供のやる気を引き出します。</p>

          <h3>豊富で楽しいクイズが盛りだくさん</h3>
          <p>「虫」「乗り物」「食べ物」「花・植物」の4ジャンルを用意し、合計1000問以上のクイズを収録しています。お子様の「知りたい！」という気持ちに応える、バラエティ豊かな内容となっています。</p>
          <p>※ 定期的に新しいクイズを追加予定です！</p>

          <h2>対象年齢</h2>
          <ul>
            <li>小学生（6歳～12歳）</li>
            <li>男の子、女の子</li>
          </ul>

          <h2>アプリの活用方法</h2>
          <h3>お子様にとって</h3>
          <ul>
            <li>楽しみながら学べる知識の宝庫として</li>
            <li>知的好奇心を育む学びの入り口として</li>
            <li>自信とやりがいを感じられる達成の場として</li>
          </ul>

          <h3>保護者の方にとって</h3>
          <ul>
            <li>安全で信頼できる教育コンテンツとして</li>
            <li>お子様の学習意欲を高めるきっかけとして</li>
            <li>家族での会話が広がるコミュニケーションツールとして</li>
          </ul>

        </section>

        <section>
          <p>「小学生クイズ王」はiOS/Androidで提供しています。各ストアからダウンロードしてご利用ください。</p>
          <p className="text-center download-buttons">
            <AppleStoreButton url={quizKingAppStoreUrl()}/>
            <GooglePlayButton url={quizKingGooglePlayUrl()}/>
          </p>
        </section>
      </article>

      <BackHome/>
    </Layout>
  )
}

export default QuizKing

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
