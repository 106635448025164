import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const GooglePlayButton = ({url}) => {
  const playConsoleUrl = url || akamaruGooglePlayUrl();

  return (
    <Link to={playConsoleUrl.href} target="_blank" className="app-download-button">
      <StaticImage
        width={160}
        src="../../images/common/app/google_store_button@2x.png"
        alt="あかまるどれかなダウンロードボタン"
      />
    </Link>
  )
}

export default GooglePlayButton

export const akamaruGooglePlayUrl = () => {
  const url = new URL("https://play.google.com/store/apps/details");
  const params = new URLSearchParams({
    "id" : "jp.co.danran.akamaru",
    "utm_source" : "dan-ran.co.jp",
    "utm_medium" : "referral",
    "utm_campaign" : "appIntroduction"
  });
  url.search = params.toString();

  return url
}

export const zukanGooglePlayUrl = () => {
  const url = new URL("https://play.google.com/store/apps/details");
  const params = new URLSearchParams({
    "id": "jp.co.eversense.myfirstlibrary",
    "utm_source" : "dan-ran.co.jp",
    "utm_medium" : "referral",
    "utm_campaign" : "appIntroduction"
  });
  url.search = params.toString();

  return url;
}

export const quizKingGooglePlayUrl = () => {
  const url = new URL("https://play.google.com/store/apps/details");
  const params = new URLSearchParams({
    "id": "jp.co.danran.quizking",
    "utm_source" : "dan-ran.co.jp",
    "utm_medium" : "referral",
    "utm_campaign" : "appIntroduction"
  });
  url.search = params.toString();

  return url;
}
