exports.components = {
  "component---src-pages-403-js": () => import("./../../../src/pages/403.js" /* webpackChunkName: "component---src-pages-403-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-akamaru-about-js": () => import("./../../../src/pages/akamaru/about.js" /* webpackChunkName: "component---src-pages-akamaru-about-js" */),
  "component---src-pages-akamaru-change-plan-js": () => import("./../../../src/pages/akamaru/change-plan.js" /* webpackChunkName: "component---src-pages-akamaru-change-plan-js" */),
  "component---src-pages-akamaru-contact-js": () => import("./../../../src/pages/akamaru/contact.js" /* webpackChunkName: "component---src-pages-akamaru-contact-js" */),
  "component---src-pages-akamaru-faq-0001-js": () => import("./../../../src/pages/akamaru/faq/0001.js" /* webpackChunkName: "component---src-pages-akamaru-faq-0001-js" */),
  "component---src-pages-akamaru-faq-0002-js": () => import("./../../../src/pages/akamaru/faq/0002.js" /* webpackChunkName: "component---src-pages-akamaru-faq-0002-js" */),
  "component---src-pages-akamaru-faq-0003-js": () => import("./../../../src/pages/akamaru/faq/0003.js" /* webpackChunkName: "component---src-pages-akamaru-faq-0003-js" */),
  "component---src-pages-akamaru-faq-0004-js": () => import("./../../../src/pages/akamaru/faq/0004.js" /* webpackChunkName: "component---src-pages-akamaru-faq-0004-js" */),
  "component---src-pages-akamaru-faq-0005-js": () => import("./../../../src/pages/akamaru/faq/0005.js" /* webpackChunkName: "component---src-pages-akamaru-faq-0005-js" */),
  "component---src-pages-akamaru-faq-0006-js": () => import("./../../../src/pages/akamaru/faq/0006.js" /* webpackChunkName: "component---src-pages-akamaru-faq-0006-js" */),
  "component---src-pages-akamaru-faq-0007-js": () => import("./../../../src/pages/akamaru/faq/0007.js" /* webpackChunkName: "component---src-pages-akamaru-faq-0007-js" */),
  "component---src-pages-akamaru-faq-0008-js": () => import("./../../../src/pages/akamaru/faq/0008.js" /* webpackChunkName: "component---src-pages-akamaru-faq-0008-js" */),
  "component---src-pages-akamaru-faq-0009-js": () => import("./../../../src/pages/akamaru/faq/0009.js" /* webpackChunkName: "component---src-pages-akamaru-faq-0009-js" */),
  "component---src-pages-akamaru-faq-0010-js": () => import("./../../../src/pages/akamaru/faq/0010.js" /* webpackChunkName: "component---src-pages-akamaru-faq-0010-js" */),
  "component---src-pages-akamaru-faq-0011-js": () => import("./../../../src/pages/akamaru/faq/0011.js" /* webpackChunkName: "component---src-pages-akamaru-faq-0011-js" */),
  "component---src-pages-akamaru-faq-0012-js": () => import("./../../../src/pages/akamaru/faq/0012.js" /* webpackChunkName: "component---src-pages-akamaru-faq-0012-js" */),
  "component---src-pages-akamaru-faq-js": () => import("./../../../src/pages/akamaru/faq.js" /* webpackChunkName: "component---src-pages-akamaru-faq-js" */),
  "component---src-pages-akamaru-how-to-use-promotion-js": () => import("./../../../src/pages/akamaru/how-to-use-promotion.js" /* webpackChunkName: "component---src-pages-akamaru-how-to-use-promotion-js" */),
  "component---src-pages-akamaru-privacy-js": () => import("./../../../src/pages/akamaru/privacy.js" /* webpackChunkName: "component---src-pages-akamaru-privacy-js" */),
  "component---src-pages-akamaru-term-js": () => import("./../../../src/pages/akamaru/term.js" /* webpackChunkName: "component---src-pages-akamaru-term-js" */),
  "component---src-pages-akamaru-tokushoho-js": () => import("./../../../src/pages/akamaru/tokushoho.js" /* webpackChunkName: "component---src-pages-akamaru-tokushoho-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-first-library-contact-js": () => import("./../../../src/pages/my_first_library/contact.js" /* webpackChunkName: "component---src-pages-my-first-library-contact-js" */),
  "component---src-pages-my-first-library-faq-js": () => import("./../../../src/pages/my_first_library/faq.js" /* webpackChunkName: "component---src-pages-my-first-library-faq-js" */),
  "component---src-pages-my-first-library-privacy-js": () => import("./../../../src/pages/my_first_library/privacy.js" /* webpackChunkName: "component---src-pages-my-first-library-privacy-js" */),
  "component---src-pages-my-first-library-term-js": () => import("./../../../src/pages/my_first_library/term.js" /* webpackChunkName: "component---src-pages-my-first-library-term-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-products-akamaru-games-js": () => import("./../../../src/pages/products/akamaru-games.js" /* webpackChunkName: "component---src-pages-products-akamaru-games-js" */),
  "component---src-pages-products-akamaru-index-js": () => import("./../../../src/pages/products/akamaru/index.js" /* webpackChunkName: "component---src-pages-products-akamaru-index-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-my-first-library-index-js": () => import("./../../../src/pages/products/my_first_library/index.js" /* webpackChunkName: "component---src-pages-products-my-first-library-index-js" */),
  "component---src-pages-products-quiz-king-index-js": () => import("./../../../src/pages/products/quiz_king/index.js" /* webpackChunkName: "component---src-pages-products-quiz-king-index-js" */),
  "component---src-pages-quiz-king-contact-js": () => import("./../../../src/pages/quiz_king/contact.js" /* webpackChunkName: "component---src-pages-quiz-king-contact-js" */),
  "component---src-pages-quiz-king-faq-js": () => import("./../../../src/pages/quiz_king/faq.js" /* webpackChunkName: "component---src-pages-quiz-king-faq-js" */),
  "component---src-pages-quiz-king-privacy-js": () => import("./../../../src/pages/quiz_king/privacy.js" /* webpackChunkName: "component---src-pages-quiz-king-privacy-js" */),
  "component---src-pages-quiz-king-term-js": () => import("./../../../src/pages/quiz_king/term.js" /* webpackChunkName: "component---src-pages-quiz-king-term-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

