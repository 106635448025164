import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const AppleStoreButton = ({url}) => {
  const appStoreUrl = url || akamaruAppStoreUrl();

  return (
    <Link to={appStoreUrl.href} target="_blank" className="app-download-button">
      <StaticImage
        width={160}
        src="../../images/common/app/apple_store_button@2x.png"
        alt="あかまるどれかなダウンロードボタン"
      />
    </Link>
  )
}

export default AppleStoreButton

export const akamaruAppStoreUrl = () => {
  const url = new URL("https://apps.apple.com/jp/app/id1623737891");
  const params = new URLSearchParams({
    "utm_source" : "dan-ran.co.jp",
    "utm_medium" : "referral",
    "utm_campaign" : "appIntroduction"
  });
  url.search = params.toString();

  return url;
}

export const zukanAppStoreUrl = () => {
  const url = new URL("https://apps.apple.com/jp/app/id1661129452");
  const params = new URLSearchParams({
    "utm_source" : "dan-ran.co.jp",
    "utm_medium" : "referral",
    "utm_campaign" : "appIntroduction"
  });
  url.search = params.toString();

  return url;
}

export const quizKingAppStoreUrl = () => {
  const url = new URL("https://apps.apple.com/jp/app/id6738672848");
  const params = new URLSearchParams({
    "utm_source" : "dan-ran.co.jp",
    "utm_medium" : "referral",
    "utm_campaign" : "appIntroduction"
  });
  url.search = params.toString();

  return url;
}
