import React from 'react'
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import {graphql} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";
import * as styles from 'src/styles/layout/product_card.module.scss'

const title = "製品一覧";
const description = "";

const ProductsPage = ({data}) => {
  return (
    <Layout>
      <Seo title={title} description={description}/>
      <section className="container article-list-container">
        <h1>{title}</h1>

        <section className="container">
          <p>
            子どもたちの学びと成長をサポートする、さまざまな知育アプリを提供しています。
            遊びながら学べる工夫を取り入れ、安心して使える環境づくりを大切にしています。
          </p>
          <div className={styles.cardContainer}>
            {/* StaticImageを利用するため動的にカードを生成できない。個別に定義したものを呼び出す */}
            {akamaruCard()}
            {quizCard()}
            {zukanCard()}
          </div>

        </section>
      </section>
    </Layout>
  )
}

const akamaruCard = () => {
  const path = "/products/akamaru";
  const src = "../../images/akamaru/feature-graphic.png";
  const alt = "タッチであそぼ!あかまるどれかな?"
  const description = "大人気「あかまるフレンズ」シリーズの知育アプリ";

  return (
    <div className={styles.card}>
      <a href={path} className={styles.cardLink}>
        <StaticImage
          src={src}
          alt={alt}
          className={styles.cardImage}
        />
        <p className={styles.cardDescription}>{description}</p>
      </a>
    </div>
  );
}

const quizCard = () => {
  const path = "/products/quiz_king";
  const src = "../../images/quiz_king/quiz_king_feature_graphic.png";
  const alt = "小学生クイズ王";
  const description = "1000問以上の豊富なクイズで楽しく学べる知育アプリ";

  return (
    <div className={styles.card}>
      <a href={path} className={styles.cardLink}>
        <StaticImage
          src={src}
          alt={alt}
          className={styles.cardImage}
        />
        <p className={styles.cardDescription}>{description}</p>
      </a>
    </div>
  );
}

const zukanCard = () => {
  const path = "/products/my_first_library";
  const src = "../../images/zukan/zukan_feature_graphic.png";
  const alt = "はじめてのずかん";
  const description = "英語も学べる！はじめてのずかん";

  return (
    <div className={styles.card}>
      <a href={path} className={styles.cardLink}>
        <StaticImage
          src={src}
          alt={alt}
          className={styles.cardImage}
        />
        <p className={styles.cardDescription}>{description}</p>
      </a>
    </div>
  );
}


const ProductCard = () => {
  // StaticImageを渡すことはできないの？
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default ProductsPage;
